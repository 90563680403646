.about-me {
	background: linear-gradient(-45deg, rgb(193, 225, 193), rgb(171, 117, 242), rgb(62, 194, 179), #ea4fb7);
	/* background-size: 400% 400%; */
	animation: gradient 30s ease infinite;
	height: auto;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.about-me-content h1{
    max-width: 100%;
    margin: 0 auto;
    padding: 100px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    max-height: 100%;
    color: white;
    font-size: 30px;
    text-shadow: 
        0.5px 0.5px 0px #020b16, 
        3px 3px 0px #6868AC;
    margin-bottom: -30px;
}


.about-me-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 130px;
   
}


.about-me-prompt img {
    width: 22rem;
    height: 28rem;
    border-radius: 10rem 10rem 0 0;
    flex: 0 0 auto;
    margin-right: 100px;
    margin-left: 100px;
    border-style: solid;
    border-width: 0.5vh;

}

.about-me-prompt .text {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 50%;
    text-align: left;
}

.about-me-prompt .text h1 {
    font-size: 50px;
    /* color: #fadd33 */
    /* color: white; */
    color: black;
}

.about-me-prompt .text p {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* color: white; */
    color: black;
    font-size: 20px;
    line-height: 1.6;
}

div .line
{
    margin-top: 10vh;
    background-color: #000;
    height: 1px;
    width: 50%;
    margin-left: 25%;
    margin-bottom: -2%;
}

/********** Skills ************/
.skills h1{
    text-align: left;
    max-width: 100%;
    margin: 0 auto;
    padding: 100px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    max-height: 100%;
    /* color: white; */
    color: black;
    font-size: 35px;
    /* text-shadow: 
        0.5px 0.5px 0px #020b16, 
        2px 2px 0px #6868AC,
        4px 4px 0px #c3b66d; */
    margin-bottom: -4%;
}

.skills-text {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    margin-right: 20%;
    margin-left: 20%;
    font-size: 3vh;
    line-height: 4vh;
    margin-top: -5vh;
    font-weight: 500;
}

.east-icons {
    margin-bottom: -1vh;
    margin-left: 1vh;
}

.skills-text .more-here:hover {
    /* color: #03b8f4; */
    color: rgb(213, 209, 209);
    cursor: pointer;
    
}



.skill-bars {
    display: grid;
    grid-template-columns: auto auto;
    list-style: none;
    margin: 1rem 0 3rem;
    margin-left: 3%;
  }
  .skill-bars li {
    height: .5rem;
    background: #3f4650;
    width: 70%;
    margin-bottom: 3.5rem;
    padding: 0;
    position: relative;
    margin-left: 5%;
  }
  .skill-bars li strong {
    position: absolute;
    left: 0;
    top: -2rem;
    font-family: "poppins-bold", sans-serif;
    color: #0e0c0c;
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-size: 1rem;
    line-height: 2.4rem;
  }
  .skill-bars li .progress {
    /* background: #9d2ce3; */
    background: rgb(241, 164, 69);
    position: relative;
    height: 100%;
  }
  .skill-bars li .progress span {
    position: absolute;
    right: 0;
    top: -3.0rem;
    display: block;
    font-family: "poppins-regular", sans-serif;
    color: white;
    font-size: 0.9rem;
    line-height: 1;
    background: #313131;
    padding: .6rem .6rem;
    border-radius: 3px;
  }
  .skill-bars li .progress span::after {
    position: absolute;
    left: 50%;
    bottom: -5px;
    margin-left: -5px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #313131;
    content: "";
  }
  
  .skill-bars li .percent5   { width: 5%; }
  .skill-bars li .percent10  { width: 10%; }
  .skill-bars li .percent15  { width: 15%; }
  .skill-bars li .percent20  { width: 20%; }
  .skill-bars li .percent25  { width: 25%; }
  .skill-bars li .percent30  { width: 30%; }
  .skill-bars li .percent35  { width: 35%; }
  .skill-bars li .percent40  { width: 40%; }
  .skill-bars li .percent45  { width: 45%; }
  .skill-bars li .percent50  { width: 50%; }
  .skill-bars li .percent55  { width: 55%; }
  .skill-bars li .percent60  { width: 60%; }
  .skill-bars li .percent65  { width: 65%; }
  .skill-bars li .percent70  { width: 70%; }
  .skill-bars li .percent75  { width: 75%; }
  .skill-bars li .percent80  { width: 80%; }
  .skill-bars li .percent85  { width: 85%; }
  .skill-bars li .percent90  { width: 90%; }
  .skill-bars li .percent95  { width: 95%; }
  .skill-bars li .percent100 { width: 100%; }



/********** Fun Facts ************/
.fun-facts h1{
    text-align: left;
    max-width: 100%;
    margin: 0 auto;
    padding: 100px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    max-height: 100%;
    /* color: white; */
    color: black;
    font-size: 35px;
    /* text-shadow: 
        0.5px 0.5px 0px #020b16, 
        2px 2px 0px #6868AC,
        4px 4px 0px #c3b66d; */
    margin-bottom: -4%;
}

.fun-facts-prompt {
    display: flex;
    align-items: center;
}

.fun-facts-prompt .pet{
    width: 120px;
    height: 100px;
    flex: 0 0 auto;
    margin-right: 1vh;
    margin-left: 10%;
}

/* .fun-facts-prompt .bubble {
    flex: 1;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
} */


.fun-facts-prompt .bubble-left {
    position: relative;
    background: #c4b1e1;
    color: #1a1111;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3vh;
    line-height: 60px;
    box-shadow: 5px 5px 2px 0px #484430;
    text-align: center;
    width: 42%;
    height: 60px;
    border-radius: 10px;
    padding: 0px;
    margin-left: 100px;
}
.fun-facts-prompt .bubble-left:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    text-align: center;
    border-style: solid;
    border-color: transparent #c4b1e1;
    border-width: 10px 24px 10px 0;
    top: 71%;
    left: -24px;
    margin-top: -14px;
}

.fun-facts-prompt .pet-right{
    width: 100px;
    height: 100px;
    flex: 0 0 auto;
    margin-right: 1vh;
    margin-left: 10%;
}


.fun-facts-prompt .bubble-right {
    position: relative;
    background: #c4b1e1;
    color: #1a1111;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 19px;
    line-height: 60px;
    box-shadow: 5px 5px 2px 0px #484430;
    text-align: center;
    width: 500px;
    height: 60px;
    border-radius: 10px;
    padding: 0px;
    margin-left: 40%;
}
.fun-facts-prompt .bubble-right:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #c4b1e1;
    border-width: 10px 0 10px 24px;
    top: 71%;
    right: -24px;
    margin-top: -10px;
}

.bottom-spacing {
    margin-bottom: 8%;
}




@media only screen and (max-width: 1000px) {
    .about-me-prompt img {
        width: 14rem;
        height: 19rem;
        border-radius: 10rem 10rem 0 0;
        flex: 0 0 auto;
        margin-right: 100px;
        margin-left: 100px;
        border-style: solid;
        border-width: 0.5vh;
        margin-left: -2vh;
    }

    .about-me-prompt .text h1 {
        font-size: 50px;
        color: #fadd33
    }

    .about-me-content h1{
        max-width: 100%;
        margin: 0 auto;
        padding: 100px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        max-height: 100%;
        color: white;
        font-size: 20px;
        text-shadow: 
            0.5px 0.5px 0px #020b16, 
            3px 3px 0px #6868AC;
        margin-bottom: -30px;
    }

    .about-me-prompt .text p {
        color: white;
        font-size: 19px;
        line-height: 1.6;
    }

    .skills h1{
        text-align: left;
        max-width: 100%;
        margin: 0 auto;
        padding: 100px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        max-height: 100%;
        color: white;
        font-size: 35px;
        margin-left: -5vh;
        text-shadow: 
            0.5px 0.5px 0px #020b16, 
            2px 2px 0px #6868AC,
            4px 4px 0px #c3b66d;
        margin-bottom: -4%;
    }

    .fun-facts h1{
        font-size: 35px;
        margin-left: -7vh;
        margin-bottom: -8vh;
    }
    
    .fun-facts-prompt .pet{
        width: 110px;
        height: 100px;
        margin-right: -5vh;
        margin-left: 10vh;
    }
    
    .fun-facts-prompt .bubble-left {
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 2.5vh;
        line-height: 20px;
        width: 43%;
        height: 10%;
        margin-bottom: 3vh;
    }

    .fun-facts-prompt .bubble-right {
        padding-top: 2vh;
        padding-bottom: 3vh;
        font-size: 2.5vh;
        line-height: 20px;
        /* width: 80%; */
        height: 100%;
        margin-left: 50vh;
        margin-bottom: 3vh;
    }
    
    .fun-facts-prompt .pet-right{
        width: 110px;
        height: 100px;
        margin-left: 10vh;
    }
    

}



@media only screen and (max-width: 900px) {
    .about-me-prompt img {
        width: 10rem;
        height: 14rem;
        border-radius: 10rem 10rem 0 0;
        flex: 0 0 auto;
        margin-right: 100px;
        margin-left: 80px;
        border-style: solid;
        border-width: 0.5vh;
        margin-left: -2vh;
    }

    .about-me-prompt .text h1 {
        font-size: 25px;
        color: #fadd33;
        margin-left: -12vh;
    }

    .about-me-prompt .text p {
        color: white;
        font-size: 19px;
        line-height: 1.6;
        margin-left: -12vh;
    }

   
    .skill-bars {
        margin-left: -1vh;
        margin-top: -6vh;
    }

    .skill-bars li strong {
        font-size: 0.7rem;
    }

    .skill-bars li .progress span {
        font-size: 0.7rem;
        line-height: 0.8;
        margin-right: -3vh;
    
    }

    .skills-text {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 2.2vh;
        line-height: 1.3;
    }


}

@media only screen and (max-width: 800px) {
    .about-me-prompt img {
        width: 14rem;
        height: 19rem;
        border-radius: 10rem 10rem 0 0;
        flex: 0 0 auto;
        margin-right: 80px;
        margin-left: 100px;
        border-style: solid;
        border-width: 0.5vh;
        margin-left: 1vh;
    }

    .about-me-prompt .text h1 {
        font-size: 40px;
        color: #fadd33;
        margin-left: 1vh;
    }

    .skills h1{
        font-size: 30px;
        margin-left: -7vh;
    }

    .about-me-prompt .text p {
        color: white;
        font-size: 20px;
        line-height: 1.6;
        margin-left: 1vh;

    }

    .fun-facts h1 {
        font-size: 30px;
        margin-left: -7vh;
    }

    .fun-facts-prompt .pet{
        width: 80px;
        height: 70px;
        margin-right: -7vh;
        margin-left: 1vh;
    }
    
    .fun-facts-prompt .bubble-left {
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 2.2vh;
        line-height: 20px;
        width: 63%;
        height: 10%;
        margin-bottom: 3vh;
    }

    .fun-facts-prompt .bubble-right {
        padding-top: 2vh;
        padding-bottom: 3vh;
        font-size: 2.2vh;
        line-height: 20px;
        width: 70%;
        height: 100%;
        margin-left: 15vh;
        margin-bottom: 3vh;
    }
    
    .fun-facts-prompt .pet-right{
        width: 110px;
        height: 100px;
        margin-left: 1.5vh;
    }




}


@media only screen and (max-width: 750px) {
    .about-me-prompt img {
        width: 9rem;
        height: 12rem;
        border-radius: 10rem 10rem 0 0;
        flex: 0 0 auto;
        margin-right: 100px;
        margin-left: 80px;
        border-style: solid;
        border-width: 0.5vh;
        margin-left: 1vh;
    }

    .skills h1{
        font-size: 30px;
        margin-left: -11vh;
    }

    .about-me-prompt .text h1 {
        font-size: 20px;
        color: #fadd33;
        margin-left: -12vh;
    }

    .about-me-prompt .text p {
        color: white;
        font-size: 12px;
        line-height: 1.6;
        margin-left: -12vh;
    }

    .bottom-spacing {
        margin-bottom: 0;
    }

    /* .skills h1{
        font-size: 28px;
        margin-left: -10vh;
    } */

    .skill-bars {
        margin-left: -3vh;
        margin-top: -6vh;
    }

    .skill-bars li strong {
        font-size: 0.6rem;
    }

    .skill-bars li .progress span {
        font-size: 0.5rem;
        line-height: 0.8;
        margin-right: -3vh;
    
    }

    .skills-text {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 2.2vh;
        line-height: 1.3;
    }

    .fun-facts h1{
        font-size: 30px;
        margin-left: -10vh;
        margin-bottom: -8vh;
    }

    .fun-facts-prompt .pet{
        width: 80px;
        height: 70px;
        margin-right: -11vh;
        margin-left: 0vh;
    }
    
    .fun-facts-prompt .bubble-left {
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 2.2vh;
        line-height: 20px;
        width: 63%;
        height: 10%;
        margin-bottom: 3vh;
    }

    .fun-facts-prompt .bubble-right {
        padding-top: 2vh;
        padding-bottom: 3vh;
        font-size: 2.2vh;
        line-height: 20px;
        width: 70%;
        height: 100%;
        margin-left: 5vh;
        margin-bottom: 3vh;
    }
    
    .fun-facts-prompt .pet-right{
        width: 110px;
        height: 100px;
        margin-left: 1.5vh;
    }

}




@media only screen and (max-width: 400px) {
    .about-me-prompt img {
        width: 9rem;
        height: 12rem;
        border-radius: 10rem 10rem 0 0;
        flex: 0 0 auto;
        margin-right: 100px;
        margin-left: 80px;
        border-style: solid;
        border-width: 0.5vh;
        margin-left: 1vh;
    }

    .about-me-prompt .text h1 {
        font-size: 20px;
        color: #fadd33;
        margin-left: -12vh;
    }

    .about-me-prompt .text p {
        color: white;
        font-size: 12px;
        line-height: 1.2;
        margin-left: -12vh;
    }

    .bottom-spacing {
        margin-bottom: 0;
    }

    .skills h1{
        text-align: left;
        max-width: 100%;
        margin: 0 auto;
        padding: 100px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        max-height: 100%;
        color: white;
        font-size: 24px;
        margin-left: -13vh;
        text-shadow: 
            0.5px 0.5px 0px #020b16, 
            2px 2px 0px #6868AC,
            4px 4px 0px #c3b66d;
        margin-bottom: -4%;
        margin-left: -11vh;
    }

    .skill-bars {
        margin-left: -5vh;
        margin-top: -6vh;
    }

    .skill-bars li strong {
        font-size: 0.4rem;
    }

    .skill-bars li .progress span {
        font-size: 0.5rem;
        line-height: 0.8;
        margin-right: -2vh;
    
    }

    .skills-text {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 2vh;
        line-height: 1.3;
    }

    .fun-facts h1{
        font-size: 20px;
        margin-bottom: -8vh;
    }
    
    .fun-facts-prompt .pet{
        width: 80px;
        height: 70px;
        margin-right: -11vh;
        margin-left: 0vh;
    }
    
    .fun-facts-prompt .bubble-left {
        padding-top: 2vh;
        padding-bottom: 2vh;
        font-size: 2vh;
        line-height: 20px;
        width: 70%;
        height: 10%;
        margin-bottom: 3vh;
    }

    .fun-facts-prompt .bubble-right {
        padding-top: 2vh;
        padding-bottom: 3vh;
        font-size: 2vh;
        line-height: 20px;
        width: 70%;
        height: 100%;
        margin-left: 6vh;
        margin-bottom: 3vh;
    }
    
    .fun-facts-prompt .pet-right{
        width: 70px;
        height: 60px;
        margin-left: 2.5vh;
    }
    
}

