.footer {
    background-color: transparent;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.socialMedia svg {
    color: rgb(0, 0, 0);
    font-size: 40px;
    cursor: pointer;
    padding-left: 3vh;
  }

.footer p {
    color: #000000;
    text-align: center;
  }

.footer h4 {
  margin-top: 10vh;
}


  @media only screen and (max-width: 400px) {
    .socialMedia svg {
      font-size: 29px;
    }

    .footer p {
      color: #000000;
      text-align: center;
      font-size: 15px;
    }

    .footer h4{
      margin-top: 10vh;
      font-size: 16px;
    }
  }